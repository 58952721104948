import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NewUserRegisterForAnAssociationEngagement = () => {
  return (
    <Layout>
      <Seo
        title="How Can a New User Register for an Association Engagement?"
        description={`This guide will walk you through registering for Association Engagement using the Synkli mobile app.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`new-user-register-for-an-association-engagement`} />
    </Layout>
  )
}

export default NewUserRegisterForAnAssociationEngagement
